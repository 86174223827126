import { Transition } from '@headlessui/react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { Button, ButtonTypes } from '../components/Button';
import { Layout } from "../components/Layout";
import SEOHeader from "../components/SEOHeader";

const BlogPostCard = ({ image, title, dateString }) => {
    return (
        <a href="/blog/test" className='rounded-md bg-brown-900/80 text-white border-transparent hover:border-brown-900 border-2 box-content w-48'>
            {image}
            <p className='px-2 pt-1 font-semibold'>{title}</p>
            <p className='px-2 pb-2 text-sm'>{dateString}</p>
        </a>
    )
}

const IndexPage = () => {
    return (
        <Layout>
            <SEOHeader title="Home" />
            <div className='flex flex-col'>
                <div className='w-full relative bg-black'>
                    <StaticImage
                        src='../images/softkillgreyeagle.jpg'
                        alt="The crowd dancing to Soft Kill at the Grey Eagle"
                        className='w-full max-h-[85vh] max-w-6xl mx-auto md:mt-0 mt-12 left-1/2 -translate-x-1/2'
                    />
                    <Transition
                        className='text-center font-caladea p-4 mx-auto absolute top-0 left-1/2 -translate-x-1/2 text-white bg-black/70 rounded-b-md'
                        appear={true}
                        show={true}
                        enter="ease-out duration-500"
                        enterFrom="opacity-0 scale-95 translate-y-4"
                        enterTo="opacity-100 scale-100 translate-y-0"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95">
                        <h1 className='font-semibold text-4xl'>Jessica Fox</h1>
                        <h2 className='text-2xl whitespace-nowrap'>Audio Engineer • Asheville, NC</h2>
                    </Transition>
                    <Transition
                        className='flex flex-row flex-wrap gap-8 w-full p-2 md:pb-8 pb-2 justify-center absolute bottom-0'
                        appear={true}
                        show={true}
                        enter="ease-out duration-500"
                        enterFrom="opacity-0 scale-95 translate-y-4"
                        enterTo="opacity-100 scale-100 translate-y-0"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95">
                        <Button className='w-32' type={ButtonTypes.Link} onClick='/about' filled={false} buttonText="About Me" />
                        <Button className='w-48' type={ButtonTypes.Link} onClick='/contact' filled={true} buttonText="Contact Me" />
                    </Transition>
                </div>

                <div className='flex flex-row flex-wrap justify-center mb-4 p-4 gap-4 md:gap-8'>
                    <Transition
                        className='w-full max-w-2xl'
                        appear={true}
                        show={true}
                        enter="ease-out duration-500"
                        enterFrom="opacity-0 scale-95 translate-y-4"
                        enterTo="opacity-100 scale-100 translate-y-0"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95">
                        <StaticImage
                            src='../images/smokymountainsirenswomentothefrontfest.jpg'
                            alt='Five women standing on a stage surrounded by audio equipment'
                            className='rounded-t-md w-full'
                        />
                        <div className='bg-black/80 text-white text-center p-2 rounded-b-md italic'>
                            <p>Mixing The Smoky Mountain Sirens at the Women to The Front Festival in June 2022</p>
                            <p>Photo by <a className='underline' href="https://heatherburdittphotography.com" target="_blank">Heather Burditt Photography</a></p>
                        </div>
                    </Transition>
                    <Transition
                        className='w-full max-w-2xl space-y-3'
                        appear={true}
                        show={true}
                        enter="ease-out duration-500"
                        enterFrom="opacity-0 scale-95 translate-y-4"
                        enterTo="opacity-100 scale-100 translate-y-0"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95">
                        <h1 className='text-5xl font-ms-madi'>Welcome!</h1>
                        <p>This is a place where authentic passion for music thrives. Where the chaos of signal interpretation can be simplified, then amplified.</p>
                        <p><Link to="/contact" className='underline'>Get in touch with me</Link> if you'd like to collaborate.</p>
                    </Transition>
                </div>

                <div className="w-full mx-auto mb-4">
                    <div className="h-[1px] bg-brown-900 mx-4" />
                </div>

                <div className='w-full max-w-2xl mx-auto p-4 mb-8'>
                    <h1 className='text-2xl font-semibold mb-4'>Featured Blog Posts</h1>
                    <div className='flex flex-row flex-wrap gap-4 justify-between items-start'>
                        <BlogPostCard image={
                            <StaticImage
                                src='../images/jess.jpg'
                                alt='Jess Fox sitting at a large audio mixer.'
                                className='rounded-t-md w-48 border-b-2 border-brown-900/30'
                            />}
                            title="Hello, world!"
                            dateString="August 30, 2022"
                        />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default IndexPage;
